import { createRouter, createWebHistory } from 'vue-router'

import CertificadoView from "@/components/CertificadoView.vue";

const routes = [
  {
    path: '/certificado/:id', // Formato correto para parâmetros dinâmicos
    name: 'Certificado',
    component: CertificadoView, // Não precisa usar uma função aqui
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
