<template>
  <div>
    <h1>Certificado</h1>
    <iframe
        v-if="pdfUrl"
        :src="pdfUrl"
        width="100%"
        height="600px"
        frameborder="0"
    ></iframe>
    <p v-else>Certificado não encontrado.</p>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  setup() {
    const route = useRoute()
    const certificadoId = route.params.id
    const pdfUrl = `/certificados/${certificadoId}.pdf`

    return {
      pdfUrl,
    }
  },
}
</script>
